// UI - libs
import { Box, Divider, Drawer, Grid, IconButton, Stack, Typography } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

// UI - internal
import PriorityIcon from "../../../../components/ServiceUIComponents/JobErrorService/PriorityIcon";

// Hooks & State
import { useJobsErrorCenterState } from "../../useJobsErrorCenterStore";

import { JobErrorCenterApi } from "../../../../utils/apiInstances/JobErrorCenterApiInstance";

// Libs
import dayjs from "dayjs";
import _ from "lodash";
import JobsErrorDetailsDrawerSection from "./JobErrorDetailsDrawerSection";
import { Teams } from "../../../../utils/helpers/field-enums";
import { AssignmentField } from "./AssignmentField";

import { useState } from "react";
import { buildJobDetailsProperties } from "./buildJobDetailsProperties";

interface JobErrorDetailsDrawerProps {
  fetchJobErrors: () => void;
}

export default function JobErrorDetailsDrawer({ fetchJobErrors }: JobErrorDetailsDrawerProps) {
  const [loading, setLoading] = useState<boolean>(false);

  const { selectedJobError, setSelectedJobError, editingSection } = useJobsErrorCenterState();

  const drawerOpen = selectedJobError !== null;

  const handleClose = () => {
    if (!editingSection) setSelectedJobError(null);
  };

  const handleJobReassign = async (user: { id: number; name: string }) => {
    if (selectedJobError) {
      setLoading(true);
      await JobErrorCenterApi.updateAssignee(selectedJobError.id, user.id);
      await fetchJobErrors();
      setLoading(false);
    }
  };

  const jobDetailsSectionProperties = buildJobDetailsProperties(selectedJobError);

  return (
    <Drawer anchor="right" onClose={handleClose} open={drawerOpen}>
      <Box sx={{ width: 800, position: "relative" }}>
        <Box sx={{ backgroundColor: "white", p: 2 }}>
          <Typography variant="h4">Job Error Report</Typography>
          <Typography variant="caption" sx={{ color: "gray" }}>
            Last failed at: {dayjs(selectedJobError?.failedAt).format("YYYY-MM-DD hh:mm:ss")}
          </Typography>
        </Box>
        <IconButton sx={{ position: "absolute", top: 16, right: 16 }} disabled={!!editingSection} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <Divider />
        <Box sx={{ backgroundColor: "rgba(245,245,245,1)", p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <JobsErrorDetailsDrawerSection
                label="Management"
                fetchJobErrors={fetchJobErrors}
                properties={[
                  {
                    label: "Assignee",
                    jobErrorKey: "assignee",
                    renderer: (sx = {}) => (
                      <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center" sx={sx}>
                        <AssignmentField assignee={selectedJobError?.assignee} onReassign={handleJobReassign} loading={loading} />
                        <Typography>{selectedJobError && selectedJobError.assignee?.id && selectedJobError.assignee?.name}</Typography>
                      </Stack>
                    ),
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <JobsErrorDetailsDrawerSection label="Job Details" fetchJobErrors={fetchJobErrors} properties={jobDetailsSectionProperties} />
            </Grid>
            {Object.keys(selectedJobError?.arguments ?? {})?.length > 0 && (
              <Grid item xs={12}>
                <JobsErrorDetailsDrawerSection
                  label="Arguments"
                  fetchJobErrors={fetchJobErrors}
                  properties={
                    selectedJobError && selectedJobError.arguments
                      ? Object.entries(selectedJobError.arguments).map(([key, value]) => ({
                          label: key,
                          jobErrorKey: `arguments.${key}`,
                          renderer: () => <pre style={{ textAlign: "left" }}>{JSON.stringify(value, null, 2)}</pre>,
                        }))
                      : []
                  }
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <JobsErrorDetailsDrawerSection
                editable
                label="SafeLease Info"
                fetchJobErrors={fetchJobErrors}
                properties={[
                  {
                    label: "Triaging Status",
                    jobErrorKey: "triagingStatus",
                    renderer: (priority, sx = {}) => (
                      <Stack direction="row" spacing={1} justifyContent="flex-end" sx={sx}>
                        <PriorityIcon priority={priority} />
                        <Typography>{priority}</Typography>
                      </Stack>
                    ),
                  },
                  {
                    label: "Team",
                    jobErrorKey: "team",
                    editable: true,
                    renderer: (team, sx = {}) => <Typography sx={sx}>{_.capitalize(team)}</Typography>,
                    asDropdown: true,
                    options: [
                      { value: Teams.Success, label: "Success" },
                      { value: Teams.Support, label: "Support" },
                      { value: Teams.Product, label: "Product" },
                    ],
                  },
                  {
                    label: "Resolution Notes",
                    jobErrorKey: "csmNotes",
                    editable: true,
                  },
                  {
                    label: "CSM Resolution Steps",
                    jobErrorKey: "csmResolutionSteps",
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <JobsErrorDetailsDrawerSection
                editable
                label="Error Info"
                fetchJobErrors={fetchJobErrors}
                properties={[
                  {
                    label: "Failed at",
                    jobErrorKey: "failedAt",
                    renderer: (failedAt, sx = {}) => <Typography sx={sx}>{dayjs(failedAt).format("YYYY-MM-DD hh:mm:ss")}</Typography>,
                  },
                  {
                    label: "Account",
                    jobErrorKey: "accountUsername",
                    renderer: (accountUsername) => <Typography>{accountUsername}</Typography>,
                  },
                  {
                    label: "Error",
                    jobErrorKey: "error",
                  },
                  {
                    label: "Error Notes",
                    jobErrorKey: "errorNotes",
                    editable: true,
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Drawer>
  );
}
