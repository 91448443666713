import { Box, Card, Modal, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { ClaimsApi } from "../../../../../../../utils/apiInstances/ClaimsApiInstance";
import { useQueryClient } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { SafeLeaseButton } from "@safelease/components";
import { ClaimStatus } from "@safelease/service-utilities/enums";

type ReopenClaimConfirmationModalProps = {
  setModalOpen: (modalOpen: boolean) => void;
  claimId: string;
};

function ReopenClaimConfirmationModal({ setModalOpen, claimId }: ReopenClaimConfirmationModalProps) {
  const [reason, setReason] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const isReasonValid = reason.trim().length > 0;

  const handleReopenClaim = async () => {
    try {
      setIsLoading(true);
      await ClaimsApi.reopenClaim(claimId, { newStatus: ClaimStatus.NeedsReview, reason: reason.trim() });
      queryClient.invalidateQueries({ queryKey: ["getClaim", claimId] });
      enqueueSnackbar("Claim reopened", { variant: "success" });
      setModalOpen(false);
    } catch (error) {
      enqueueSnackbar("Failed to reopen claim", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      onClose={() => setModalOpen(false)}
      open={true}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card>
        <Stack padding={2} spacing={2}>
          <Typography>Please provide an explanation of why you needed to re-open this claim</Typography>
          <TextField value={reason} onChange={(e) => setReason(e.target.value)} multiline minRows={2} maxRows={5} />
          <Stack direction="row" spacing={1.5}>
            <Tooltip title="Please provide an explanation of why you needed to re-open this claim">
              <Box>
                <SafeLeaseButton
                  disabled={!isReasonValid}
                  color="error"
                  variant="contained"
                  onClick={handleReopenClaim}
                  loading={isLoading}
                >
                  Reopen Claim
                </SafeLeaseButton>
              </Box>
            </Tooltip>
            <SafeLeaseButton color="navy" variant="outlined" onClick={() => setModalOpen(false)}>
              Cancel
            </SafeLeaseButton>
          </Stack>
        </Stack>
      </Card>
    </Modal>
  );
}

export { ReopenClaimConfirmationModal };
