import { Box, Button, Card, Grid, Theme, Typography } from "@mui/material";
import JobsErrorCenterMetricCard from "./JobsErrorCenterMetricCard";
import dayjs from "dayjs";
import { useJobsErrorCenter, useJobsErrorCenterState } from "../useJobsErrorCenterStore";
import { SafeLeaseDataGrid } from "@safelease/components";
import JobErrorDetailsDrawer from "./JobErrorDetailsDrawer/JobErrorDetailsDrawer";
import { liveProductionLatencyByFMSColumnDefs } from "./liveProductionLatencyByFmsColumnDefs";
import { formatWithCommas } from "../../../utils/formatters";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { JobError } from "@safelease/service-utilities/types/Jobs/JobResponses";
import { useQuery } from "@tanstack/react-query";
import { TitleHeader } from "../../../components/ServiceUIComponents/JobErrorService/TitleHeader";
import { mostStaleJobByFMSColumnDefs } from "./mostStaleJobByFMSColumnDefs";
import { useConditionalJobsErrorCenterColumnDefs } from "./useConditionalJobErrorCenterColumnDefs.tsx/useConditionalJobsErrorCenterColumnDefs";

interface FmsLatency {
  type: string;
  latency: string;
}

interface FmsLatencyRow extends FmsLatency {
  id: string;
}

interface EngineeringErrorReportProps {}

const dataGridStyling = {
  borderColor: "#EBEFF7",
  "& .MuiDataGrid-cell": {
    borderColor: "#EBEFF7",
    cursor: "pointer",
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
    outline: "none", // Removes cell selection outline
  },
};

export const EngineeringErrorReport: React.FC<EngineeringErrorReportProps> = () => {
  const navigate = useNavigate();
  const { visibleColumns, updateVisibleColumns, setSelectedJobError } = useJobsErrorCenterState();
  const { fetchJobErrors, fetchLatencyByFms, fetchLiveProductionJobData, fetchMostStaleJobByFms, jobs } = useJobsErrorCenter();
  const { liveProductionJobReportGridColDefs } = useConditionalJobsErrorCenterColumnDefs();

  const jobsErrorQueryResult = useQuery({
    queryKey: ["jobs-error-report"],
    queryFn: fetchJobErrors,
  });

  const latencyByFmsQueryResult = useQuery({
    queryKey: ["latency-by-fms"],
    queryFn: fetchLatencyByFms,
  });

  const liveProductionJobDataQueryResult = useQuery({
    queryKey: ["production-job-data"],
    queryFn: fetchLiveProductionJobData,
  });

  const mostStaleJobByFmsQueryResult = useQuery({
    queryKey: ["most-stale-job-by-fms"],
    queryFn: fetchMostStaleJobByFms,
  });

  if (
    jobsErrorQueryResult.isPending ||
    liveProductionJobDataQueryResult.isPending ||
    latencyByFmsQueryResult.isPending ||
    mostStaleJobByFmsQueryResult.isPending
  )
    return "Loading...";
  if (
    jobsErrorQueryResult.isError ||
    liveProductionJobDataQueryResult.isError ||
    latencyByFmsQueryResult.isError ||
    mostStaleJobByFmsQueryResult.isError
  )
    return "Error...";

  const refresh = () => {
    jobsErrorQueryResult.refetch();
    liveProductionJobDataQueryResult.refetch();
    latencyByFmsQueryResult.refetch();
    mostStaleJobByFmsQueryResult.refetch();
  };

  const pendingJobs = liveProductionJobDataQueryResult.data.pendingCount;
  const latestCompletedJob = liveProductionJobDataQueryResult.data.lastCompletedTimestamp;
  const latencyByFMS = latencyByFmsQueryResult.data;
  const mostStaleJobByFMS = mostStaleJobByFmsQueryResult.data;

  const productFailures = jobs.filter((error: JobError) => error.team === "product");
  const nonProductFailures = jobs.filter((error: JobError) => error.team !== "product");
  const productJobs = jobs.filter((jobError: JobError) => jobError.team === "product");
  const latencyByFMSRows: FmsLatencyRow[] = latencyByFMS.map((fmsLatencyObj: FmsLatency): FmsLatencyRow => {
    return {
      ...fmsLatencyObj,
      id: uuidv4(),
    };
  });
  const mostStaleJobByFMSRows = mostStaleJobByFMS.map((r: any) => ({ ...r, id: uuidv4() }));

  const remapJobRows = (jobs: JobError[]) => {
    return jobs.map((job: JobError) => {
      return {
        ...job,
        navigate,
        fetchJobErrors,
      };
    });
  };

  return (
    <Box sx={{ p: 1, overflowX: "hidden" }}>
      <TitleHeader title="Live Production Job Report" sx={{ mb: 1 }} />
      <Grid container spacing={2}>
        <Grid item container alignItems="center" columnSpacing={1}>
          <Grid item>
            <Typography variant="body2" sx={{ color: (theme: Theme) => theme.palette.grey[600] }}>
              Last job completed at {dayjs(latestCompletedJob).format("MM/DD/YYYY hh:mm A")}
            </Typography>
          </Grid>
          <Grid item>
            <Button onClick={refresh}>Refresh</Button>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <JobsErrorCenterMetricCard value={formatWithCommas(pendingJobs)} label="Pending jobs" />
        </Grid>
        <Grid item xs={12} md={3}>
          <JobsErrorCenterMetricCard value={formatWithCommas(productFailures.length)} label="Product failures" />
        </Grid>
        <Grid item xs={12} md={3}>
          <JobsErrorCenterMetricCard value={formatWithCommas(nonProductFailures.length)} label="Non product failures" />
        </Grid>
        <Grid item xs={12} md={3}>
          <JobsErrorCenterMetricCard value={liveProductionJobDataQueryResult.data.latency} label="Maximum successful ingestion latency" />
        </Grid>
        <Grid item xs={12}>
          <Card elevation={0} sx={{ p: 2 }}>
            <Typography sx={{ fontWeight: "bold", mb: 2 }} variant="body1">
              Product Job Errors
            </Typography>
            <Box sx={{ minHeight: "400px", maxHeight: "60vh", overflow: "auto" }}>
              <SafeLeaseDataGrid
                sx={dataGridStyling}
                columns={liveProductionJobReportGridColDefs}
                rows={remapJobRows(productJobs)}
                getRowHeight={() => "auto"}
                editMode="cell"
                onRowClick={(params: any) => {
                  setSelectedJobError(params.row);
                }}
                columnVisibilityModel={visibleColumns}
                onColumnVisibilityModelChange={(newModel: any) => updateVisibleColumns(newModel)}
              />
            </Box>
          </Card>
          <Card elevation={0} sx={{ p: 2, marginTop: 5 }}>
            <Typography sx={{ fontWeight: "bold", mb: 2 }} variant="body1">
              Most Stale By FMS
            </Typography>
            <Box sx={{ minHeight: "200px", maxHeight: "60vh", overflow: "auto" }}>
              <SafeLeaseDataGrid
                sx={dataGridStyling}
                columns={mostStaleJobByFMSColumnDefs}
                rows={mostStaleJobByFMSRows}
                autosizeOnMount
                getRowHeight={() => "auto"}
              />
            </Box>
          </Card>
          <Card elevation={0} sx={{ p: 2, marginTop: 5 }}>
            <Typography sx={{ fontWeight: "bold", mb: 2 }} variant="body1">
              Latency By FMS
            </Typography>
            <Box sx={{ minHeight: "200px", maxHeight: "60vh", overflow: "auto" }}>
              <SafeLeaseDataGrid
                sx={dataGridStyling}
                columns={liveProductionLatencyByFMSColumnDefs}
                rows={latencyByFMSRows}
                autosizeOnMount
                getRowHeight={() => "auto"}
              />
            </Box>
          </Card>
        </Grid>
      </Grid>
      <JobErrorDetailsDrawer fetchJobErrors={fetchJobErrors} />
    </Box>
  );
};
