import { BaseActionView } from "./BaseActionView";
import { Grid, IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import { LocationDropdown } from "../LocationDropDown";
import { useDataChangeManager } from "../../useDataChangeManager";
import { SafeLeaseDatePicker } from "@safelease/components";
import { Dayjs } from "dayjs";
import { RelationshipDropDown } from "../RelationshipDropDown";
import { filterLocationsForRelationship } from "../../../../utils/helpers/filter-locations-relationship";
import { AdminRequestLocation } from "@safelease/service-utilities";
import dayjs from "dayjs";
import ClearIcon from "@mui/icons-material/Clear";
interface UpdateLiveDateViewProps {
  dateType: "liveDate" | "cancelDate";
}

// This component is a child of DataChangeManager and is used to update the live date or cancel date of a location
function UpdateLocationDateView({ dateType }: UpdateLiveDateViewProps) {
  const { getAllLocationsQuery, selectedDate, relationshipSelectorId, selectedLocationIds, setSelectedDate } = useDataChangeManager();

  const handleDateChange = (newDate: Dayjs | null) => {
    setSelectedDate(newDate);
  };

  const findLocationById = (selectedLocationIds: string[]): AdminRequestLocation | null => {
    if (selectedLocationIds.length && getAllLocationsQuery.data) {
      return getAllLocationsQuery.data.find((location: AdminRequestLocation) => location.id === +selectedLocationIds[0]) || null;
    }

    return null;
  };

  const determineLiveDate = () => {
    // If selectedDate is null, return null explicitly
    if (selectedDate === null) {
      return null;
    }

    // If selectedDate is not set but location is selected, use the location's liveDate
    if (!selectedDate && selectedLocationIds.length) {
      const location = findLocationById(selectedLocationIds);
      return dayjs(location?.liveDate) || null;
    }

    // Default to selectedDate
    return selectedDate;
  };

  const handleClear = () => {
    setSelectedDate(null); // Clear the date
  };

  const dateLabel = dateType === "liveDate" ? "Live Date" : "Cancel Date"; // Label based on the dateType

  return (
    <BaseActionView actionTitle={`Update Location ${dateLabel}`}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <RelationshipDropDown />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <LocationDropdown
          locations={filterLocationsForRelationship(getAllLocationsQuery.data, relationshipSelectorId) || []}
          sx={{ minWidth: "250px", width: "50vw" }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <SafeLeaseDatePicker
          sx={{ minWidth: "250px", width: "50vw", marginTop: "20px" }}
          onChange={handleDateChange}
          value={determineLiveDate()}
          slots={{
            textField: (params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {params.InputProps?.endAdornment}
                      <InputAdornment position="end">
                        <Tooltip title="Clear date">
                          <IconButton onClick={handleClear} edge="end" aria-label="clear date">
                            <ClearIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    </>
                  ),
                }}
              />
            ),
          }}
        />
      </Grid>
    </BaseActionView>
  );
}

export { UpdateLocationDateView };
