import { FiberManualRecord, Warning } from "@mui/icons-material";
import { Box, Checkbox, Stack, Theme, Typography } from "@mui/material";
import { formatDateAsTimeAgo } from "../../../utils/helpers/formatDateAsTimeAgo";
import { blue } from "@mui/material/colors";

interface Notification {
  id: string;
  title: string;
  text: string;
  type: string;
  readAt: Date;
  seenAt: Date;
  createdAt: Date;
  updatedAt: Date;
}

export const NotificationItem = ({
  id,
  title,
  text,
  readAt,
  createdAt,
  isSelected,
  onCheckboxChange,
}: Notification & { isSelected: boolean; onCheckboxChange: (id: string) => void }) => {
  const hasRead = readAt;

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        p: 2,
        alignItems: "center",
        bgcolor: hasRead ? "background.paper" : blue[50],
        borderBottom: (theme: Theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <Checkbox checked={isSelected} onChange={() => onCheckboxChange(id)} inputProps={{ "aria-label": `Select notification ${title}` }} />
      {!hasRead && (
        <Box sx={{ width: 10, height: 10 }}>
          <FiberManualRecord sx={{ height: 10, width: 10, color: "primary.main" }} />
        </Box>
      )}
      <Warning />
      <Stack>
        <Typography fontWeight="bold" fontSize="subtitle2">
          {title}
        </Typography>
        <Typography fontSize="body2">{text}</Typography>
        <Typography variant="caption" color="text.secondary">
          {formatDateAsTimeAgo(createdAt)}
        </Typography>
      </Stack>
    </Stack>
  );
};
