import { useMutation, useQuery } from "@tanstack/react-query";
import { ProspectsApi } from "../../../../utils/apiInstances/ProspectsApiInstance";
import { ProspectPaginationModel } from "../ProspectDataTab";
import { useState } from "react";
import { Button, Grid } from "@mui/material";
import { ProspectHumanResultStatus } from "../../tables/columnDefs";
import { ModeratorTable } from "../../tables/ModeratorTable";
import { ProspectsModerateDetailsDrawer } from "../../pages/ProspectsModerateDetailsDrawer";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { ProspectsTabEnum } from "../../pages/ProspectsPage";
import { FormattedProspectHumanResult } from "../../../../types/Prospects";

export function ModeratorTab() {
  const params = useParams();
  const tab = params["tab"];
  const [searchParams] = useSearchParams();
  const entityId = searchParams.get("id");
  const [paginationModel, setPaginationModel] = useState<ProspectPaginationModel>({ page: 0, pageSize: 10 });
  const [selectedForModerationIds, setSelectedForModerationIds] = useState<string[]>([]);

  const {
    data,
    refetch: refetchGetProspectHumanResults,
    refetch: refetchProspectHumanResults,
  } = useQuery({
    queryKey: ["getProspectHumanResultsForModerator", { page: paginationModel.page, limit: paginationModel.pageSize }],
    queryFn: async () => {
      const response = await ProspectsApi.getProspectHumanResultsForModerator({
        page: paginationModel.page,
        limit: paginationModel.pageSize,
      });
      return response.data;
    },
  });

  const onSubmitProspectHumanResultsToModerator = useMutation({
    mutationFn: async () => {
      if (!data?.prospectHumanResults) {
        return;
      }
      const filteredData = data?.prospectHumanResults.filter((prospectHumanResult) =>
        selectedForModerationIds.includes(prospectHumanResult.id),
      );
      await ProspectsApi.updateProspectHumanResultsForModerator(filteredData);
    },
    onSuccess: () => {
      refetchGetProspectHumanResults();
      setSelectedForModerationIds([]);
    },
    onError: () => {
      window.alert("An error occurred while marking the prospect as done");
    },
  });

  const formattedProspectHumanResults: FormattedProspectHumanResult[] =
    data?.prospectHumanResults.map((prospectHumanResult) => {
      return {
        ...prospectHumanResult,
        status: ProspectHumanResultStatus[prospectHumanResult.status as keyof typeof ProspectHumanResultStatus] || "—",
        placeId: {
          value: prospectHumanResult.placeIdNew || prospectHumanResult.placeIdOld,
          notFound: prospectHumanResult.placeIdNotFound,
        },
        website: {
          value: prospectHumanResult.websiteNew || prospectHumanResult.websiteOld,
          notFound: prospectHumanResult.websiteNotFound,
        },
        name: prospectHumanResult.nameNew || prospectHumanResult.nameOld,
      };
    }) || [];

  return (
    <Grid container spacing={3} paddingRight={3}>
      {/* complete moderation button */}
      <Grid item xs={12} display="flex" justifyContent="flex-end">
        <Button
          disabled={selectedForModerationIds.length === 0}
          variant="contained"
          onClick={() => onSubmitProspectHumanResultsToModerator.mutate()}
        >
          Complete Moderation
        </Button>
      </Grid>

      {/* moderator table */}
      <Grid item xs={12}>
        <ModeratorTable
          formattedProspectHumanResults={formattedProspectHumanResults}
          totalCount={data?.totalCount || 0}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          setSelectedForModerationIds={setSelectedForModerationIds}
        />
      </Grid>

      {/* details drawer */}
      {entityId && tab === ProspectsTabEnum.MODERATOR && (
        <ProspectsModerateDetailsDrawer refetchProspectHumanResults={refetchProspectHumanResults} />
      )}
    </Grid>
  );
}
