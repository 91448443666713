import { GridColDef } from "@mui/x-data-grid-premium";
import { Box, IconButton, Tooltip } from "@mui/material";
import { useFmsLoginExtension } from "../../../../hooks/useFmsLoginChromeExtension";
import { JobError } from "@safelease/service-utilities/types/Jobs/JobResponses";
import { baseGeneralErrorReportGridColDefs } from "./baseGeneralErrorCenterColumnDefs";
import { Login } from "@mui/icons-material";
import { baseLiveProductionJobReportColumnDefs } from "./baseLiveProductionJobReportColumnDefs";

export enum Teams {
  Success = "success",
  Support = "support",
  Product = "product",
}

export function useConditionalJobsErrorCenterColumnDefs() {
  const { showButton: showFmsLoginColumn, chromeExtensionInstalled, loginToFms } = useFmsLoginExtension();

  const fmsLoginColumn: GridColDef = {
    field: "fmsLogin",
    headerName: "FMS Login",
    align: "center",
    headerAlign: "center",
    renderCell: ({ row }: { row: JobError }) => {
      const missingValidLocationId = row.locationId === -1; // The locationId is set to -1 if it is not available in the jobErrorReport api endpoint
      const onClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        if (missingValidLocationId) return;
        await loginToFms({ locationId: row.locationId });
      };
      return (
        <Tooltip
          title={
            missingValidLocationId
              ? "locationId for this job is not available"
              : chromeExtensionInstalled
                ? "Login to FMS"
                : "FMS Login Chrome Extension is not installed correctly."
          }
        >
          <Box>
            <IconButton onClick={onClick} disabled={!chromeExtensionInstalled || missingValidLocationId}>
              <Login />
            </IconButton>
          </Box>
        </Tooltip>
      );
    },
  };

  // TODO controlled team updates
  const generalErrorReportGridColDefs: GridColDef[] = [
    ...(showFmsLoginColumn ? [fmsLoginColumn] : []),
    ...baseGeneralErrorReportGridColDefs,
  ];

  const liveProductionJobReportGridColDefs: GridColDef[] = [
    ...(showFmsLoginColumn ? [fmsLoginColumn] : []),
    ...baseLiveProductionJobReportColumnDefs,
  ];

  return {
    generalErrorReportGridColDefs,
    liveProductionJobReportGridColDefs,
  };
}
