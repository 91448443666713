export const appConfig = {
  aws: {
    cognitoUserPoolId: String(import.meta.env.VITE_COGNITO_USER_POOL_ID),
    cognitoAppClientId: String(import.meta.env.VITE_COGNITO_APP_CLIENT_ID),
  },
  environment:
    window.location.hostname === "operations.safelease.com"
      ? "production"
      : window.location.hostname === "operations-demo.safelease.com"
        ? "demo"
        : window.location.hostname === "operations-staging.safelease.com"
          ? "staging"
          : "development",
  claimsApiBaseUrl: String(import.meta.env.VITE_CLAIMS_API_BASE_URL || "http://localhost:8000"),
  authServiceApiBaseUrl: String(import.meta.env.VITE_AUTH_SERVICE_API_BASE_URL || "http://localhost:8001"),
  privatePolicyApiBaseUrl: String(import.meta.env.VITE_PRIVATE_POLICY_API_BASE_URL || "http://localhost:8080/api"),
  prospectsApiBaseUrl: String(import.meta.env.VITE_PROSPECTS_API_BASE_URL || "http://localhost:8420"),
  notificationsApiBaseUrl: String(import.meta.env.VITE_NOTIFICATIONS_API_BASE_URL || "http://localhost:8002"),
};
