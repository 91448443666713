import React, { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useDataChangeManagerState } from "../useDataChangeManager";
import { AdminRequestLlc } from "@safelease/service-utilities";

interface LlcDropdownProps {
  llcs: AdminRequestLlc[]; // List of all llcs fetched from the core api
  sx?: any;
}

function LlcDropdown({ llcs, sx }: LlcDropdownProps) {
  const [options, setOptions] = useState<AdminRequestLlc[]>(llcs); // Options shown in the dropdown
  const { selectedLlcId, setSelectedLlcId } = useDataChangeManagerState();

  useEffect(() => {
    setOptions(llcs); // Update options when llcs prop changes
  }, [llcs]);

  const handleLlcChange = (_event: React.SyntheticEvent, selectedLlc: AdminRequestLlc | null) => {
    setSelectedLlcId(selectedLlc ? selectedLlc.id.toString() : ""); // Update selected ID
  };

  // Filters down options (llcs) based on the llc id when a user types in the input
  const filterOptions = (
    options: AdminRequestLlc[],
    state: { inputValue: string; getOptionLabel: (option: AdminRequestLlc) => string },
  ) => {
    const filteredOptions = options.filter(
      (llc: AdminRequestLlc) => llc.id.toString().includes(state.inputValue), // Check id (as string)
    );
    return filteredOptions;
  };

  return (
    <Autocomplete
      options={options} // Filtered list of llcs
      getOptionLabel={(option) => `${option.id} - ${option.name}`} // Display name in dropdown
      isOptionEqualToValue={(option, value) => option.id === value.id} // Ensure equality by llc ID
      renderInput={(params) => <TextField {...params} sx={sx} label="Select Llc" variant="outlined" />}
      noOptionsText="No llcs found" // Message when no llcs match search
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.id} - {option.name}
        </li>
      )}
      onChange={handleLlcChange}
      filterOptions={filterOptions}
      value={llcs.find((llc) => llc.id === +selectedLlcId) || null} // Set the selected value
      sx={{ minWidth: "250px", width: "50vw" }}
    />
  );
}

export { LlcDropdown };
