import { Notifications } from "@mui/icons-material";
import { Badge, Box, Button, IconButton, Popover, Theme, Typography } from "@mui/material";
import { useState } from "react";
import { useNotifications } from "../../../hooks/notifications/useNotifications";
import { NotificationItem } from "./NotificationItem";

export function NotificationsMenu() {
  const { getNotificationsQuery, markNotificationsAsUnreadMutation, markNotificationsAsReadMutation } = useNotifications();
  const notifications = getNotificationsQuery.data?.notifications || [];
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedNotificationIds, setSelectedNotificationIds] = useState<string[]>([]);
  const unreadNotifications = notifications.filter((n) => !n.readAt);
  const readNotifications = notifications.filter((n) => n.readAt);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedNotificationIds([]); // Clear selections on close
  };

  const handleCheckboxChange = (id: string) => {
    setSelectedNotificationIds((prev) => (prev.includes(id) ? prev.filter((notificationId) => notificationId !== id) : [...prev, id]));
  };

  const handleMarkAsRead = async () => {
    await markNotificationsAsReadMutation.mutate(selectedNotificationIds);
    // Clear selections
    setSelectedNotificationIds([]);
  };

  const handleMarkAsUnread = async () => {
    await markNotificationsAsUnreadMutation.mutate(selectedNotificationIds);
    // Clear selections
    setSelectedNotificationIds([]);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <IconButton size="medium" onClick={handleClick}>
        <Badge badgeContent={unreadNotifications.length} color="error">
          <Notifications />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              width: 400,
              maxHeight: 400,
              overflowY: "auto",
            },
          },
        }}
      >
        {/* If no notifications, show 'No notifications available' message */}
        {notifications.length === 0 && (
          <Box
            sx={{
              p: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography variant="body2" color="text.secondary">
              No notifications available
            </Typography>
          </Box>
        )}

        {/* Show notifications when they are available */}
        {notifications.length > 0 && (
          <>
            <Box
              sx={{
                p: 2,
                borderBottom: (theme: Theme) => `1px solid ${theme.palette.divider}`,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                size="small"
                disabled={!selectedNotificationIds.length}
                onClick={handleMarkAsRead}
              >
                Mark as Read
              </Button>
              <Button variant="contained" color="info" size="small" disabled={!selectedNotificationIds.length} onClick={handleMarkAsUnread}>
                Mark as Unread
              </Button>
            </Box>
            {unreadNotifications.map((notification: any) => (
              <NotificationItem
                {...notification}
                key={notification.id}
                isSelected={selectedNotificationIds.includes(notification.id)}
                onCheckboxChange={handleCheckboxChange}
              />
            ))}
            {readNotifications.map((notification: any) => (
              <NotificationItem
                {...notification}
                key={notification.id}
                isSelected={selectedNotificationIds.includes(notification.id)}
                onCheckboxChange={handleCheckboxChange}
              />
            ))}
          </>
        )}
      </Popover>
    </div>
  );
}
