export enum UserRoles {
  EMPLOYEE = "employee",
  MANAGER = "manager",
  ADMIN = "admin",
}

export enum ActionValueEnum {
  NONE = "none",
  DELETE_LOCATION = "deleteLocation",
  UPDATE_LIVE_DATE = "updateLiveDate",
  OFFBOARD_FACILITIES = "offboardFacilities",
  UPDATE_ACCOUNT_CREDENTIALS = "updateAccountCredentials",
  UPDATE_LOCATION_SERVICE_ID = "updateLocationServiceId",
  UPDATE_LOCATION_CANCEL_DATE = "updateLocationCancelDate",
  UPDATE_LOCATION_EXEMPT_SFDC_PUSHING = "updateLocationExemptSfdcPushing",
  UPDATE_USER_ROLE = "updateUserRole",
  DISCONNECT_FMS_FROM_LOCATION = "disconnectFmsFromLocation",
  UPDATE_RELATIONSHIP_BILLING_REQUIRED = "updateRelationshipBillingRequired",
  DELETE_LLC = "deleteLlc",
  UPDATE_RELATIONSHIP_PROTECTION_TYPE = "updateRelationshipProtectionType",
  UPDATE_LOCATION_INSURANCE_ENABLED = "updateLocationInsuranceEnabled",
  ADMIN_REQUESTS_HISTORY = "adminRequestsHistory",
}

interface ActionType {
  value: ActionValueEnum;
  label: string;
}

export const actionTypes: ActionType[] = [
  { value: ActionValueEnum.NONE, label: "Select an update type" },
  { value: ActionValueEnum.DELETE_LOCATION, label: "Delete Location" },
  { value: ActionValueEnum.UPDATE_LIVE_DATE, label: "Update Location Live Date" },
  { value: ActionValueEnum.OFFBOARD_FACILITIES, label: "Offboard Facilities" },
  { value: ActionValueEnum.UPDATE_ACCOUNT_CREDENTIALS, label: "Credential Helper" },
  { value: ActionValueEnum.UPDATE_LOCATION_SERVICE_ID, label: "Update Location Service ID" },
  { value: ActionValueEnum.UPDATE_LOCATION_CANCEL_DATE, label: "Update Location Cancel Date" },
  { value: ActionValueEnum.UPDATE_LOCATION_EXEMPT_SFDC_PUSHING, label: "Update Location Exempt From SFDC Pushing" },
  { value: ActionValueEnum.UPDATE_USER_ROLE, label: "Update User Role" },
  { value: ActionValueEnum.DISCONNECT_FMS_FROM_LOCATION, label: "Disconnect Fms From Location" },
  { value: ActionValueEnum.UPDATE_RELATIONSHIP_BILLING_REQUIRED, label: "Update Relationship Billing Required" },
  { value: ActionValueEnum.DELETE_LLC, label: "Delete LLC" },
  { value: ActionValueEnum.UPDATE_RELATIONSHIP_PROTECTION_TYPE, label: "Update Relationship Protection Type" },
  { value: ActionValueEnum.UPDATE_LOCATION_INSURANCE_ENABLED, label: "Update Location Insurance Enabled" },
  { value: ActionValueEnum.ADMIN_REQUESTS_HISTORY, label: "Admin Requests History" },
];
