import { useNavigate, useSearchParams } from "react-router-dom";
import { ProspectsTabEnum } from "./ProspectsPage";
import {
  Button,
  Chip,
  Drawer,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ProspectsApi } from "../../../utils/apiInstances/ProspectsApiInstance";
import { Close, ErrorOutline } from "@mui/icons-material";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { FacilityOverviewAccordion } from "../components/moderators/FacilityOverviewAccordion";
import { WebsiteAccordion } from "../components/moderators/WebsiteAccordion";
import { PlaceIdAccordion } from "../components/moderators/PlaceIdAccordion";
import { isStorageFacilityMap, prospectHumanStatusMap } from "../constants";
import { ProspectsModerateDetailsDrawerFormData } from "../../../types/Prospects";
import { ProspectHumanResult } from "@safelease/service-utilities";
import { enqueueSnackbar } from "notistack";

export function ProspectsModerateDetailsDrawer({ refetchProspectHumanResults }: { refetchProspectHumanResults: () => void }) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const reviewProspectId = searchParams.get("id");
  const form = useForm<ProspectsModerateDetailsDrawerFormData>({
    defaultValues: {
      websiteNotFound: false,
      website: {
        moderatorInput: "",
        selection: "reviewer",
      },
      name: {
        moderatorInput: "",
        selection: "reviewer",
      },
      placeIdNotFound: false,
      placeId: {
        moderatorInput: "",
        selection: "reviewer",
      },
      isStorageFacility: "yes",
      notes: "",
      googleStreetViewUrl: "",
    },
  });

  if (!reviewProspectId) {
    return null;
  }

  const { data: prospectHumanResult, refetch: refetchProspectHumanResult } = useQuery({
    // I do not want to retry on mount because I want to show the error page if the prospect human result is not found
    retry: false,
    queryKey: ["getProspectHumanResultDetails", reviewProspectId],
    queryFn: async () => {
      if (!reviewProspectId) {
        navigate("/404");
        return null;
      }

      // using a try catch here because I didn't want to add a use effect to reflect the isError state
      try {
        const response = await ProspectsApi.getProspectHumanResultById(reviewProspectId);
        form.reset({
          websiteNotFound: response.data.websiteNotFound || false,
          website: {
            moderatorInput: "",
            selection: "reviewer",
          },
          name: {
            moderatorInput: "",
            selection: "reviewer",
          },
          placeIdNotFound: response.data.placeIdNotFound || false,
          placeId: {
            moderatorInput: "",
            selection: "reviewer",
          },
          isStorageFacility: response.data.isStorageFacility || "yes",
          notes: response.data.notes || "",
          googleStreetViewUrl: response.data.googleStreetViewUrl || "",
        });

        return response.data;
      } catch (error) {
        navigate("/404");
        return null;
      }
    },
  });

  const onSubmit = (data: ProspectsModerateDetailsDrawerFormData) => {
    const massagedData = {
      nameNew: determineInput(data, "name", prospectHumanResult),
      websiteNew: determineInput(data, "website", prospectHumanResult),
      placeIdNew: determineInput(data, "placeId", prospectHumanResult),
      notes: data.notes,
      googleStreetViewUrl: data.googleStreetViewUrl,
      isStorageFacility: data.isStorageFacility === "noPlaceIdFound" ? "unknown" : data.isStorageFacility,
      placeIdNotFound: data.placeIdNotFound,
      websiteNotFound: data.websiteNotFound,
    };
    onUpdateProspectHumanResult.mutate(massagedData);
  };

  const onUpdateProspectHumanResult = useMutation({
    mutationFn: async (formData: Partial<ProspectHumanResult>) => {
      await ProspectsApi.updateProspectHumanResultsForModerator([{ id: reviewProspectId, ...formData }]);
    },
    onSuccess: () => {
      refetchProspectHumanResult();
      refetchProspectHumanResults();
      // close the drawer on success
      navigate(`/prospects/${ProspectsTabEnum.MODERATOR}`);
    },
    onError: () => {
      enqueueSnackbar("An error occurred while marking the prospect as done", { variant: "error" });
    },
  });

  if (!prospectHumanResult) {
    return null;
  }

  return (
    <Drawer
      anchor="right"
      onClose={() => navigate(`/prospects/${ProspectsTabEnum.MODERATOR}`)}
      open={!!reviewProspectId}
      PaperProps={{ sx: { width: "70%" } }}
    >
      <Grid container>
        {/* header */}
        <Grid item container justifyContent="space-between" padding={2}>
          <Grid item container xs={9} rowGap={1}>
            <Grid item xs={12}>
              <Typography variant="h6">{prospectHumanResult.nameNew || prospectHumanResult.nameOld || "No name"}</Typography>
            </Grid>

            {/* status chip */}
            <Grid item xs={12}>
              <Chip
                color={prospectHumanStatusMap[prospectHumanResult.status as keyof typeof prospectHumanStatusMap].color}
                label={prospectHumanStatusMap[prospectHumanResult.status as keyof typeof prospectHumanStatusMap].label}
              />
            </Grid>

            {/* only show if the reviewer marked this facility as not a storage facility */}
            {!prospectHumanResult?.isStorageFacility && (
              <Grid item container alignItems="center" paddingTop={1}>
                <InputAdornment position="start">
                  <ErrorOutline color="error" />
                </InputAdornment>
                <Typography variant="subtitle1">Reviewer marked this facility as not a storage facility</Typography>
              </Grid>
            )}
          </Grid>
          <Grid item>
            <IconButton onClick={() => navigate(`/prospects/${ProspectsTabEnum.MODERATOR}`)}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>

        {/* form */}
        <Grid item xs={12}>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <Grid container rowGap={2}>
                {/* facility overview accordion */}
                <Grid item xs={12}>
                  <FacilityOverviewAccordion prospectHumanResult={prospectHumanResult} />
                </Grid>

                {/* website accordion */}
                <Grid item xs={12}>
                  <WebsiteAccordion prospectHumanResult={prospectHumanResult} />
                </Grid>

                {/* placeId accordion */}
                <Grid item xs={12}>
                  <PlaceIdAccordion prospectHumanResult={prospectHumanResult} />
                </Grid>

                <Grid item container paddingX={3} rowGap={3}>
                  {/* is storage facility */}
                  <Grid item xs={12}>
                    <Controller
                      name="isStorageFacility"
                      control={form.control}
                      render={({ field }) => (
                        <FormControl>
                          <FormLabel>Is this a storage facility?</FormLabel>
                          <RadioGroup {...field}>
                            {Object.entries(isStorageFacilityMap).map(([key, value]) => (
                              <FormControlLabel key={key} value={key} control={<Radio />} label={value} />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      )}
                    />
                  </Grid>

                  {/* google street view url */}
                  <Grid item xs={12}>
                    <Controller
                      name="googleStreetViewUrl"
                      control={form.control}
                      render={({ field }) => <TextField fullWidth label="Google Street View URL" {...field} />}
                    />
                  </Grid>

                  {/* notes */}
                  <Grid item xs={12}>
                    <Controller
                      name="notes"
                      control={form.control}
                      render={({ field }) => (
                        <TextField
                          placeholder="Where did you find the information? Please list the search engine (Google, Yahoo, etc.) used, the URL, and the steps for why and how you found the information."
                          fullWidth
                          label="Notes"
                          multiline
                          rows={4}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                {/* submit button */}
                <Grid item xs={12} padding={2} paddingBottom={4}>
                  <Button fullWidth type="submit" variant="contained" color="primary">
                    Mark as Complete
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Grid>
      </Grid>
    </Drawer>
  );
}

// helpers

type SelectableField = "name" | "website" | "placeId";

export const determineInput = (
  data: ProspectsModerateDetailsDrawerFormData,
  fieldName: SelectableField,
  prospectHumanResult: ProspectHumanResult | null | undefined,
) => {
  if (!prospectHumanResult) {
    return null;
  }

  switch (data[fieldName].selection) {
    case "reviewer":
      return prospectHumanResult[`${fieldName}New`];
    case "original":
      return prospectHumanResult[`${fieldName}Old`];
    case "moderator":
      return data[fieldName].moderatorInput;
    default:
      return null;
  }
};
