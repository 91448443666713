import { Box, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { SafeLeaseButton } from "@safelease/components";
import { ClaimsApi } from "../../../../../../../../utils/apiInstances/ClaimsApiInstance";
import { useParams } from "react-router";
import { enqueueSnackbar } from "notistack";
import { NumericFormat } from "react-number-format";
import { forwardRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { Dayjs } from "dayjs";
import * as Sentry from "@sentry/react";
import { GetPolicyDataForClaimResponse } from "@safelease/service-utilities";
import { UseQueryResult } from "@tanstack/react-query";

type CreateManualPolicyProps = {
  setModalState: (state: "select" | "create") => void;
  policyDataForClaimQuery: UseQueryResult<GetPolicyDataForClaimResponse, Error>;
  setModalOpen: (open: boolean) => void;
};

type AddPolicyData = {
  coverage: string;
  startDate: Dayjs | null;
  cancelDate: Dayjs | null;
};

function CreateManualPolicy({ setModalState, policyDataForClaimQuery, setModalOpen }: CreateManualPolicyProps) {
  const { claimId } = useParams();
  if (!claimId) return null;
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<AddPolicyData>({
    defaultValues: {
      coverage: "",
      startDate: null,
      cancelDate: null,
    },
  });

  // Watch the date fields
  const startDate = watch("startDate");
  const cancelDate = watch("cancelDate");

  // Check if either date is invalid
  const hasInvalidDate = Boolean((startDate && !startDate.isValid()) || (cancelDate && !cancelDate.isValid()));

  const handleAddPolicy = async (data: AddPolicyData) => {
    setIsLoading(true);
    const { coverage, startDate, cancelDate } = data;
    const coverageInPennies = Number(coverage.replace(/\D/g, "")); // Remove non-numeric characters and convert to number in pennies

    try {
      await ClaimsApi.createManualPolicyForClaim(claimId, {
        coverage: coverageInPennies,
        startDate: startDate ? startDate.format("YYYY-MM-DD") : null,
        cancelDate: cancelDate ? cancelDate.format("YYYY-MM-DD") : null,
      });
      enqueueSnackbar("Manual policy created successfully", { variant: "success" });
      policyDataForClaimQuery.refetch();
      setModalOpen(false);
    } catch (error) {
      enqueueSnackbar("Error creating manual policy", { variant: "error" });
      Sentry.captureException(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Stack component="form" onSubmit={handleSubmit(handleAddPolicy)} spacing={2}>
      <Typography sx={{ fontWeight: 600, fontSize: 18 }}>Create Manual Policy</Typography>
      <Controller
        name="coverage"
        control={control}
        rules={{ required: "coverage is required" }}
        render={({ field }) => (
          <NumericFormatWrapper
            {...field}
            label="Coverage amount"
            helperText={errors.coverage?.message}
            error={!!errors.coverage}
            fullWidth
          />
        )}
      />
      <Stack direction="row" spacing={2}>
        <Controller name="startDate" control={control} render={({ field }) => <DatePicker {...field} label="Start date (Optional)" />} />
        <Controller name="cancelDate" control={control} render={({ field }) => <DatePicker {...field} label="Cancel date (Optional)" />} />
      </Stack>
      <Stack direction="row" spacing={2} justifyContent="flex-end">
        <SafeLeaseButton variant="outlined" color="navy" onClick={() => setModalState("select")} disabled={isLoading}>
          Cancel
        </SafeLeaseButton>
        <Tooltip title={hasInvalidDate ? "Please enter valid dates" : ""}>
          <Box>
            <SafeLeaseButton variant="contained" color="navy" type="submit" loading={isLoading} disabled={hasInvalidDate}>
              Add Policy
            </SafeLeaseButton>
          </Box>
        </Tooltip>
      </Stack>
    </Stack>
  );
}

const NumericFormatWrapper = forwardRef((props: any, ref) => {
  return (
    <NumericFormat
      {...props}
      allowNegative={false}
      fixedDecimalScale
      thousandSeparator=","
      prefix="$"
      decimalScale={2}
      fullWidth
      customInput={TextField}
      getInputRef={ref}
    />
  );
});

export { CreateManualPolicy };
