import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { BaseActionView } from "./BaseActionView";
import { useDataChangeManager } from "../../useDataChangeManager";
import { RelationshipDropDown } from "../RelationshipDropDown";

const relationshipProtectionTypeOptions = [
  { value: "tenantProtection", label: "Tenant Protection" },
  { value: "tenantInsurance", label: "Tenant Insurance" },
];

function UpdateRelationshipProtectionTypeView() {
  const { relationshipProtectionType, relationshipSelectorId, setRelationshipProtectionType } = useDataChangeManager();

  const handleSelectChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    setRelationshipProtectionType(value);
  };

  return (
    <BaseActionView actionTitle="Update Relationship Protection Type">
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <RelationshipDropDown />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <FormControl variant="outlined">
          <InputLabel id="relationshipProtectionType">Select Value</InputLabel>
          <Select
            id="relationshipProtectionType"
            labelId="relationshipProtectionType"
            value={relationshipProtectionType.toString()}
            label="Select Value"
            onChange={handleSelectChange}
            sx={{ minWidth: "250px", width: "50vw" }}
            disabled={!relationshipSelectorId}
          >
            {relationshipProtectionTypeOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </BaseActionView>
  );
}

export { UpdateRelationshipProtectionTypeView };
