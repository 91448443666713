import { Route, Routes } from "react-router-dom";
import { AppLayout } from "../../components/AppLayout";
import { BrowserRouter } from "react-router-dom";
import { ClaimsNavigator } from "../../services/claims/pages/ClaimsNavigator";
import { SelectedClaimPage } from "../../services/claims/pages/SelectedClaimPage";
import { ComplianceCenter } from "../../services/compliance/pages/ComplianceCenter";
import { Home } from "../../pages/Home";
import { PageNotFound } from "./PageNotFound";
import JobsErrorCenter from "../../services/jobs-error-center/pages/JobsErrorCenter.tsx";
import { DataChangeManager } from "../../services/data-change-manager/pages";
import { LinearProgress } from "@mui/material";
import { InternalUserManagement } from "../../services/internalUserManagement/pages/InternalUserManagement.tsx";
import { TenantUserManagement } from "../../services/tenantUserManagement/pages/TenantUserManagement.tsx";
import { CreateNewUser } from "../../services/internalUserManagement/pages/CreateNewUser/CreateNewUser.tsx";
import { EngineeringErrorReport } from "../../services/jobs-error-center/pages/EngineeringErrorReport.tsx";
import { useAuthStore } from "../../hooks/useAuthStore.ts";
import ProspectsPage from "../../services/prospects/pages/ProspectsPage.tsx";
import { SelectedLegacyClaimPage } from "../../services/claims/pages/SelectedLegacyClaimPage";
import { UnitBreakdownReport } from "../../services/unitBreakdownReport/index.tsx";
import TodoListPage from "../../services/todo-list/pages/TodoListPage.tsx";

type AuthenticatedRouterProps = {};

/**
 * These are all of the routes that exist on the FE for the authenticated user.
 */
function AuthenticatedRouter({}: AuthenticatedRouterProps) {
  const userAttributes = useAuthStore((state) => state.userAttributes);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route element={<AppLayout />}>
            <Route path="/" element={<Home />} />
            {userAttributes?.isJobCenterEnabled === "true" && (
              <>
                <Route path="error_center" element={<JobsErrorCenter />} />
                <Route path="engineering-error-report" element={<EngineeringErrorReport />} />
              </>
            )}
            {userAttributes?.isClaimsEnabled === "true" && (
              <>
                <Route path="claims" element={<ClaimsNavigator />} />
                <Route path="claims/:claimId" element={<SelectedClaimPage />} />
                <Route path="legacy-claims/:legacyClaimId" element={<SelectedLegacyClaimPage />} />
              </>
            )}
            {userAttributes?.isComplianceEnabled === "true" && <Route path="compliance" element={<ComplianceCenter />} />}
            {userAttributes?.isUserSettingsAdmin === "true" && (
              <>
                <Route path="internal-user-management" element={<InternalUserManagement />} />
                <Route path="internal-user-management/create-new-user" element={<CreateNewUser />} />
              </>
            )}
            {userAttributes?.isTenantUserAdmin === "true" && <Route path="tenant-user-management" element={<TenantUserManagement />} />}
            {userAttributes?.isAdminReqsEnabled === "true" && <Route path="change-manager" element={<DataChangeManager />} />}
            {userAttributes?.isProspectsEnabled === "true" && <Route path="/prospects" element={<ProspectsPage />} />}
            {userAttributes?.isProspectsEnabled === "true" && <Route path={"/prospects/:tab?"} element={<ProspectsPage />} />}
            {userAttributes?.isReportingAdmin === "true" && <Route path={"/unit-breakdown"} element={<UnitBreakdownReport />} />}
            {userAttributes?.isTodoListEnabled === "true" && <Route path={"/todo-list"} element={<TodoListPage />} />}
          </Route>
          <Route path="*" element={!userAttributes ? <LinearProgress color="inherit" /> : <PageNotFound />} />
        </Route>
        <Route path="/404" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export { AuthenticatedRouter };
