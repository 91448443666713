import { useQuery, useMutation } from "@tanstack/react-query";
import { NotificationsApi } from "../../utils/apiInstances/NotificationsApiInstance";

export const useNotifications = () => {
  /***** QUERIES START *****/
  const getNotificationsQuery = useQuery({
    queryKey: ["notifications"],
    queryFn: async () => {
      const response = await NotificationsApi.getNotifications();
      return response.data;
    },
  });
  /***** QUERIES END *****/

  /***** MUTATIONS START *****/
  const markNotificationsAsReadMutation = useMutation({
    mutationFn: (notificationIds: string[]) => NotificationsApi.markAsRead(notificationIds),
    onSuccess: () => {
      getNotificationsQuery.refetch();
    },
  });

  const markNotificationsAsSeenMutation = useMutation({
    mutationFn: (notificationIds: string[]) => NotificationsApi.markAsSeen(notificationIds),
  });

  const markNotificationsAsUnreadMutation = useMutation({
    mutationFn: (notificationIds: string[]) => NotificationsApi.markAsUnread(notificationIds),
    onSuccess: () => {
      getNotificationsQuery.refetch();
    },
  });

  const sendNotificationMutation = useMutation({
    mutationFn: ({ types, service, recipientIds }: { types: string[]; service: string; recipientIds: string[] }) =>
      NotificationsApi.sendNotification({ types, service, recipientIds }),
  });

  /***** MUTATIONS END *****/
  return {
    getNotificationsQuery,
    markNotificationsAsReadMutation,
    markNotificationsAsSeenMutation,
    markNotificationsAsUnreadMutation,
    sendNotificationMutation,
  };
};
