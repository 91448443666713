import Grid from "@mui/material/Grid";
import { Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { IngestionTabContainer } from "../ingestion/IngestionTabContainer";

export default function TodoListPage() {
  const [tab, setTab] = useState<string>("ingestion");

  const tabs = ["ingestion", "protection"];

  return (
    <Grid container spacing={2}>
      {/* tabs */}
      <Grid item xs={12}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={tab} onChange={(_, value) => setTab(value)}>
            {tabs.map((tab) => (
              <Tab key={tab} label={tab} value={tab} />
            ))}
          </Tabs>
        </Box>
      </Grid>

      {/* tab content */}
      <Grid item xs={12}>
        {tab === "ingestion" && <IngestionTabContainer />}
        {/* {tab === "protection" && <ProtectionTabContainer />} */}
      </Grid>
    </Grid>
  );
}
