import { useDataChangeManager } from "../../useDataChangeManager";
import { BaseActionView } from "./BaseActionView";
import { Grid } from "@mui/material";
import { RelationshipDropDown } from "../RelationshipDropDown";
import { LlcDropdown } from "../LlcDropDown";
import { filterLlcsForRelationship } from "../../../../utils/helpers/filter-llcs-relationship";

interface DeleteLlcProps {}

function DeleteLlcView({}: DeleteLlcProps) {
  const { getAllLlcsQuery, relationshipSelectorId } = useDataChangeManager();

  return (
    <BaseActionView actionTitle="Delete LLC">
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <RelationshipDropDown />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <LlcDropdown llcs={filterLlcsForRelationship(getAllLlcsQuery.data, relationshipSelectorId) || []} sx={{ width: "100%" }} />
      </Grid>
    </BaseActionView>
  );
}

export { DeleteLlcView };
