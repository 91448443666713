import { Box, Tooltip } from "@mui/material";
import { useSelectedClaimPageStore } from "../../../useSelectedClaimPageStore";
import { SafeLeaseButton } from "@safelease/components";
import { ClaimStatus } from "@safelease/service-utilities/enums";
import { useParams } from "react-router";
import { useState } from "react";
import { ReopenClaimConfirmationModal } from "./ReopenClaimConfirmationModal";

type ReopenClaimButtonProps = {};

function ReopenClaimButton({}: ReopenClaimButtonProps) {
  const { claimId } = useParams<{ claimId: string }>();
  if (!claimId) return null;

  const [reopenClaimModalOpen, setReopenClaimModalOpen] = useState<boolean>(false);

  const claim = useSelectedClaimPageStore((state) => state.claim);
  const isSettledClaim = claim?.status === ClaimStatus.ClosedSettled || Boolean(claim?.officialReleaseEmailSentAt);
  const tooltipText = isSettledClaim ? "Cannot reopen a settled claim" : "";

  return (
    <Tooltip title={tooltipText}>
      <Box>
        <SafeLeaseButton
          onClick={() => setReopenClaimModalOpen(true)}
          disabled={isSettledClaim}
          color="error"
          variant="contained"
          size="small"
        >
          Reopen
        </SafeLeaseButton>
        {reopenClaimModalOpen && <ReopenClaimConfirmationModal setModalOpen={setReopenClaimModalOpen} claimId={claimId} />}
      </Box>
    </Tooltip>
  );
}

export { ReopenClaimButton };
