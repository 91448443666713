import { SafeLeaseButton } from "@safelease/components";
import { useState } from "react";
import { SendSettlementConfirmationModal } from "./SendSettlementConfirmationModal";
import { Box, Tooltip } from "@mui/material";
import { useSelectedClaimPageStore } from "../../../useSelectedClaimPageStore";

type SendSettlementButtonProps = {
  disabled: boolean;
};

/**
 * This component houses the Send settlement button and its corresponding confirmation modal which openes when the button is clicked
 * This button should be enabled when the current claim has a settlement offer that the tenant has accepted from the prior flow.
 * disabled from the current claim having any "closed" status ("Closed - Settled", "Closed - Denied", "Closed-Abandoned")
 */

function SendSettlementButton({ disabled }: SendSettlementButtonProps) {
  const [sendSettlementModalOpen, setSendSettlementModalOpen] = useState<boolean>(false);

  const claim = useSelectedClaimPageStore((state) => state.claim);
  const officialReleaseEmailSent = Boolean(claim?.officialReleaseEmailSentAt);
  const tooltipText = officialReleaseEmailSent ? "Release has already been sent. Waiting for tenant to sign." : "";
  return (
    <Tooltip title={tooltipText}>
      <Box>
        <SafeLeaseButton
          onClick={() => setSendSettlementModalOpen(true)}
          disabled={disabled || officialReleaseEmailSent}
          size="small"
          disableElevation
          variant="contained"
        >
          Send settlement
        </SafeLeaseButton>
        {sendSettlementModalOpen && <SendSettlementConfirmationModal setModalOpen={setSendSettlementModalOpen} />}
      </Box>
    </Tooltip>
  );
}

export { SendSettlementButton };
