import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { RelationshipDropDown } from "../RelationshipDropDown";
import { BaseActionView } from "./BaseActionView";
import { LocationDropdown } from "../LocationDropDown";
import { filterLocationsForRelationship } from "../../../../utils/helpers/filter-locations-relationship";
import { useDataChangeManager } from "../../useDataChangeManager";
import { AdminRequestLocation } from "@safelease/service-utilities";
import { useEffect } from "react";

interface UpdateLocationInsuranceEnabledViewProps {}

const insuranceEnabledOptions = [
  { label: "Disabled", value: "false" },
  { label: "Enabled", value: "true" },
];

function UpdateLocationInsuranceEnabledView({}: UpdateLocationInsuranceEnabledViewProps) {
  const { getAllLocationsQuery, relationshipSelectorId, selectedLocationIds, locationInsuranceEnabled, setLocationInsuranceEnabled } =
    useDataChangeManager();

  const findLocationById = (selectedLocationIds: string[]): AdminRequestLocation | null => {
    if (selectedLocationIds.length && getAllLocationsQuery.data) {
      return getAllLocationsQuery.data.find((location: AdminRequestLocation) => location.id === +selectedLocationIds[0]) || null;
    }
    return null;
  };

  // Update the state with the insuranceEnabled value of the selected location
  // This ensures that the insuranceEnabled value is reflected in the dropdown when the user selects a location.
  useEffect(() => {
    const selectedLocation = findLocationById(selectedLocationIds);
    if (selectedLocation) {
      setLocationInsuranceEnabled(selectedLocation.insuranceEnabled); // Update state to reflect the selected location's value
    }
  }, [selectedLocationIds, getAllLocationsQuery.data]);

  const handleSelectChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    setLocationInsuranceEnabled(value === "true");
  };

  return (
    <BaseActionView actionTitle="Update Location Insurance Enabled">
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <RelationshipDropDown />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <LocationDropdown
          locations={filterLocationsForRelationship(getAllLocationsQuery?.data, relationshipSelectorId) || []}
          sx={{ minWidth: "250px", width: "50vw" }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} alignItems="flex-start">
        <FormControl variant="outlined">
          <InputLabel id="insuranceEnabled">Select Value</InputLabel>
          <Select
            id="insuranceEnabled"
            labelId="insuranceEnabled"
            value={locationInsuranceEnabled?.toString() || ""}
            label="Select Value"
            onChange={handleSelectChange}
            sx={{ minWidth: "250px", width: "50vw" }}
          >
            {insuranceEnabledOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </BaseActionView>
  );
}

export { UpdateLocationInsuranceEnabledView };
