import { Box, Stack, Typography } from "@mui/material";
import { useSelectedClaimPageStore } from "../../useSelectedClaimPageStore";
import { DenyClaimButton } from "./DenyClaimButton";
import { SendOfferButton } from "./SendOfferButton";
import { SendSettlementButton } from "./SendSettlementButton";
import dayjs from "dayjs";
import { SolicitFeedbackButton } from "./SolicitFeedbackButton";
import { ReopenClaimButton } from "./ReopenClaimButton";

type ActionButtonsSectionProps = {};

function ActionButtonsSection({}: ActionButtonsSectionProps) {
  const claim = useSelectedClaimPageStore((state) => state.claim);
  const isClosedClaim = Boolean(claim?.closedAt);
  const formattedClosedAt = claim?.closedAt ? dayjs(claim.closedAt).format("MMM D, YYYY") : null;
  const closedReason = claim?.closedReason ?? "";

  // boolean value that indicates if the claim has an accepted offer
  // if it does we should display the SendSettelmentButton instead of the SendOfferButton
  // SendSettlementButton is the actual formstack sign flow for the release form that the tenant has to sign
  const hasAcceptedOffer = claim?.settlementOffers?.some((offer) => offer.tenantAccepted);

  return (
    <Stack spacing={1}>
      <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
        {formattedClosedAt && (
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography sx={{ color: "red.main", fontWeight: 500, fontSize: 15 }}>
              Closed '{closedReason}' - {formattedClosedAt}
            </Typography>
            <ReopenClaimButton />
          </Stack>
        )}
      </Box>
      <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
        <SolicitFeedbackButton disabled={!!claim?.feedbackSolicitedAt} />
        <DenyClaimButton disabled={isClosedClaim} />
        {hasAcceptedOffer ? <SendSettlementButton disabled={isClosedClaim} /> : <SendOfferButton disabled={isClosedClaim} />}
      </Stack>
    </Stack>
  );
}

export { ActionButtonsSection };
