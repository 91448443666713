import { useEffect, useState } from "react";
import Error from "../../../../components/SafeLeaseError";
import { Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, ListItemText } from "@mui/material";
import { JobErrorCenterApi } from "../../../../utils/apiInstances/JobErrorCenterApiInstance";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";

interface Job {
  relationshipId: string;
  locationId: string;
  method: string;
  status: string;
  failureCount: number;
  error: string;
  failedAt: string | Date;
}

/** Popup dialog to retry a job instead of navigating to a new page */
export function RetryDialog({
  open,
  onClose,
  onSuccess,
  jobId,
}: {
  open: boolean;
  onClose: () => void;
  onSuccess: () => Promise<void>;
  jobId: string;
}) {
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false);

  // todo, swap to an imported "Job" type, but the current "Job" type has a
  // typo in the package so we're using a cast to get around this
  const [job, setJob] = useState<null | Job>(null);
  const { enqueueSnackbar } = useSnackbar();

  const getJob = async () => {
    if (jobId) {
      setLoading(true);
      try {
        const fetchedJob = await JobErrorCenterApi.getJob(jobId);
        setJob(fetchedJob.data as unknown as Job);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (open) {
      getJob();
    }
  }, [jobId, open]);

  if (err) return <Error />;

  const retry = async function () {
    if (jobId) {
      setLoading(true);
      try {
        await JobErrorCenterApi.retryJob(jobId);
        await getJob();
        await onSuccess();
        setLoading(false);
        onClose();
        enqueueSnackbar("Job successfully queued to retry", { variant: "success" });
      } catch (err) {
        console.log({ err });
        setErr(true);
        setLoading(false);
        enqueueSnackbar("Error retrying job", { variant: "error" });
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Retry Job</DialogTitle>
      <DialogContent>
        {loading && <CircularProgress />}
        {err && <Error />}
        {!loading && !job && <Error />}
        {!loading && job && (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <ListItemText primary="Relationship" secondary={job?.relationshipId ?? "-"} />
            </Grid>
            <Grid item xs={6}>
              <ListItemText primary="Location" secondary={job?.locationId ?? "-"} />
            </Grid>
            <Grid item xs={6}>
              <ListItemText primary="Job" secondary={job?.method ?? "-"} />
            </Grid>
            <Grid item xs={6}>
              <ListItemText primary="Status" secondary={<StatusChip status={job?.status} />} />
            </Grid>
            <Grid item xs={6}>
              <ListItemText primary="Last failed at" secondary={dayjs(job?.failedAt).format("MMM DD, hh:mm A")} />
            </Grid>
            <Grid item xs={6}>
              <ListItemText primary="Retries" secondary={job?.failureCount ?? "-"} />
            </Grid>
            <Grid item xs={12}>
              <ListItemText primary="Error" secondary={job?.error ?? "-"} />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      {!err && job && (
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={retry} variant="contained" color="primary" disableElevation>
            Retry
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

const StatusChip = ({ status }: { status: number | string }) => {
  if (status == 0) return <Chip label="Pending" color="info" size="small" />;
  if (status == 1) return <Chip label="Completed" color="success" size="small" />;
  if (status == 2) return <Chip label="Failed" color="error" size="small" />;
  if (status == 3) return <Chip label="Running" color="info" size="small" />;
  if (status == 4) return <Chip label="Paused" color="error" size="small" />;
  return <Chip label="Unknown" color="error" size="small" />;
};
